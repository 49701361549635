import {Dropdown} from 'bootstrap';
import {handleFloatingSearchVisibility} from './searchForm/displayControl';
import {debounce} from 'throttle-debounce';
import MicroModal from 'micromodal';

/* Functions:
    toggleLogoContainer, show/hide siteLogo based on navOpen & screenWidth
    scrollToNextSection, "SHOW MORE" at the bottom of main hero, scroll
    closeMiniCart, manual handling of miniCart's closeButton
    handleOneTimeCollapsible,
    handleListItemClickZIndex,
    handleNoticePopup,
    handleCurrencyItemClick,
    handleMobileNavCloseOnNavItemClick,
 */

/* In Mobile opening NAV bar put lang switcher to logo position */
export function toggleLogoContainer() {
    $(document).ready(function () {
        function toggleLogo() {
            const isExpanded = $(this).attr('aria-expanded') === 'true';
            if (isExpanded) {
                $('.navbar-brand').hide();
            } else {
                $('.navbar-brand').css('opacity', 0);
                $('.navbar-brand').animate({opacity: 1}, 300);
                $('.navbar-brand').show();
            }
            handleMobileNavLogoVisibility();
        }

        $('.navbar-toggler').on('click touchstart', toggleLogo);
        $(window).on('resize', handleMobileNavLogoVisibility);
    });
}

function handleMobileNavLogoVisibility() {
    let $mobileNav = $('.navbar-toggler');
    let $logoDiv = $('.navbar-brand');
    let mobileNavOpen = ($mobileNav.attr('aria-expanded') === 'true');

    if ($(window).width() > 991) {
        if (mobileNavOpen) {
            if ($logoDiv.is(':not(:visible)')) {
                $logoDiv.show();
            }
        }
    } else {
        if (mobileNavOpen) {
            if ($logoDiv.is(':visible')) {
                $logoDiv.hide();
            }
        }
    }
}

export function closeMiniCart() {
    var dropdown = new Dropdown($('#mini-cart-button')[0]);
    dropdown.hide();
    $('.mobile-cart-overlay').hide();
}

/* The script is designed to handle the behavior of a "show more" button that expands collapsible content when clicked.
It uses jQuery to attach a click event handler to elements with the .show-more class, and then opens the targeted coll-
apse element and removes the collapse class from it to display more content. */
export function handleOneTimeCollapsible(className) {
    $(document).ready(function() {
        $(className).on('click', function(event) {
            let target = $(this).data('bs-target');

            event.preventDefault();
            $(target).collapse('show');
            $(target).prop('disabled', true);
            $(target).find('div.list-item').appendTo($(target).parent());
            $(this).remove();
        });
    });
}

/* List items that are clicked ought to come-in-front so that the shadows would not stay behind other tabs */
export function handleListItemClickZIndex() {
    var zIndex = 100;

    $('.collapsible-list .list-item').on({
        mouseenter: function () {
            zIndex++;
            $(this).css('z-index', zIndex);
        },
        mouseleave: function () {
            $(this).css('z-index', '');
        },
    });
}

export function scrollToNextSection() {
    $(document).ready(function  () {
        $('.js-scroll-to-next-section').on('click', function() {
            const currentSection = $(this).closest('section');
            const nextSection = currentSection.next('section');
            let headerHeightCss = $('.main-header-inner').css('height');
            let headerHeightVal = parseFloat(headerHeightCss);

            if (nextSection.length) {
                $('html, body').animate({
                    scrollTop: nextSection.offset().top - headerHeightVal,
                }, 300);
            }
        });
    });
}

export function handleCurrencyItemClick() {
    $('.js-currencyItem').on('click', function() {
        window.location = $(this).data('href');
    });
}

function handleMiniCartClose() {
    $('#mini-cart-close-btn').on('click keyup', function() {
        closeMiniCart();
    });

    $(document).on('click', function(event) {
        if ( !$(event.target).closest('#mini-cart-dropdown').length
            && !$(event.target).closest('#mini-cart-button').length ) {
            closeMiniCart();
        }
    });
}
export function handleMiniCartOpenCloseClick() {
    let $overLay = $('.mobile-cart-overlay');
    let $miniCartBtn = $('#mini-cart-button');
    let dropdown = new Dropdown($miniCartBtn[0]);

    $miniCartBtn.on('click keyup', function(/* e */) {
        /* e.preventDefault(); @todo removed this, AFTER tryFix the MiniCart fail open after reload (mobile) */
        if ($overLay.is(':visible')){
            closeMiniCart();
        } else {
            $overLay.show();
            dropdown.show();
            handleFloatingSearchVisibility();
        }
    });
    handleMiniCartClose();
}

export function handleMobileNavCloseOnNavItemClick () {
    $('.nav-link').on('click', function() {
        let $mobileNav = $('.navbar-toggler');
        if ($mobileNav.attr('aria-expanded') === 'true') {
            $mobileNav.attr('aria-expanded', 'false');
            $mobileNav.trigger('click');
        }
    })
}

export function showContentHideButton(buttonClass, contentClass) {
    $(buttonClass).on('click touchstart', function(e) {
        e.preventDefault();
        let $productItem = $(contentClass);

        $productItem.show();
        $productItem.removeClass('hidden');
        $productItem.removeClass('hiddenOnMobile');
        $(buttonClass).remove();
    })
}

export function applyScrollableIndicator(selector) {
    let $container = $(selector);

    if ($container.length === 0) {
        return; // Exit the function if the container is not found @todo implement more appropriate JS loading
    }

    let $scrollbox = $container.find('.scrollbox');

    let setShadows = debounce(100, function() {
        let maxScrollTop = $scrollbox[0].scrollHeight - $scrollbox[0].clientHeight;

        if ( Math.round($scrollbox.scrollTop()) > 0) {
            $container.addClass('off-top');
        } else {
            $container.removeClass('off-top');
        }

        /* ISSUE +1 is needed for mobile */
        if ( Math.round($scrollbox.scrollTop() + 1)  >= maxScrollTop || maxScrollTop <= 0) {
            $container.removeClass('off-bottom');
        } else {
            $container.addClass('off-bottom');
        }
    });

    $container.on('contentUpdated', function() {
        setShadows();
    });

    let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setShadows();
            }
        });
    });

    observer.observe($scrollbox[0]);
    $scrollbox.on('scroll', setShadows);
}

export function initCookieModal() {
    MicroModal.init({
        disableScroll: false, // [6]
        disableFocus: true, // [7]
        awaitOpenAnimation: false, // [8]
        awaitCloseAnimation: false, // [9]
    });
    MicroModal.show('notification-cookie');
}
