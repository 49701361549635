import {Dropdown} from 'bootstrap';
import Cookies from 'js-cookie';
import {debounce} from 'throttle-debounce';
import {
    handleFloatingSearchVisibilityThrottled,
    handleFloatingSearchVisibility,
} from './searchForm/displayControl';

export default function () {
    const $form = $('.js-searchForm');
    const $input = $form.find('.js-searchForm-input');
    const $inputAjaxTrigger = $form.find('.js-searchForm-input-ajax');
    const $button = $form.find('.js-searchForm-submit');
    const $resultsContainer = $form.find('.js-searchForm-results-inner-container');
    const $loading = $form.find('.js-searchForm-loading');
    const $country = $form.find('.js-searchForm-country');
    const $countryAjaxTrigger = $form.find('.js-searchForm-country-ajax');

    /*const $results = $form.find('.js-searchForm-results');
    const $modal = $('.js-notificationModal');
    const $modalAcceptButton = $('.js-notificationModal-accept');
    const $modalRejectButton = $('.js-notificationModal-reject');
    const $modalLoading = $('.js-notificationModal-accept-loading');

    const $infoBox = $('.js-infoBox-search-result')
    const $infoBoxRedirectButton = $('.js-infoBox-redirect-button');
    const $infoBoxLoading = $('.js-infoBox-redirect-loading');*/

    const isSearchingButtonColor = '#b1b1b1';
    const debounceRate = typeof window.theme.searchDebounce !== 'undefined' ? window.theme.searchDebounce : 600;

    const $companyInputDropdown = $resultsContainer[0];
    const $searchFormInput = $('.js-searchForm-input')[0];
    const $searchFormSelectCountry = $('#country-select-dropdown-btn')[0];

    const popperConfigForFixedHeader = {
        popperConfig: {
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        padding: {top: 72},
                    },
                },
                {
                    name: 'flip',
                    options: {
                        padding: {top: 72},
                    },
                },
            ],
        },
    };

    let $resultsDropdown, $inputDropdown;

    if ($companyInputDropdown) {
        $resultsDropdown = Dropdown.getOrCreateInstance($companyInputDropdown);
    }

    if ($searchFormInput) {
        $inputDropdown = Dropdown.getOrCreateInstance($searchFormInput, popperConfigForFixedHeader);
    }

    if ($searchFormSelectCountry) {
        Dropdown.getOrCreateInstance($searchFormSelectCountry, popperConfigForFixedHeader);
    }

    let searchTerm;

    const throttledScrollHandler = handleFloatingSearchVisibilityThrottled();

    /* Events' hooks
     */
    $(document).ready(function () {
        handleFloatingSearchVisibility();

        /*
         * We have PHP version, so this is currently deprecated
         *
        let $selectedCountry = $country.filter('.selected');
        if ($selectedCountry.length) {
          selectCountry($selectedCountry, $form.find('.form-select-cont'))
        }
        */
    });

    $('.navbar-toggler').on('click keyup', function () {
        setTimeout(function () {
            handleFloatingSearchVisibility();
        }, 200);
    })

    $(window).on('scroll', throttledScrollHandler);
    $(window).on('resize', throttledScrollHandler);
    $(window).on('load', function () {
        $input.on('input click', function () {
            $('.clear-company-input').css('display', this.value ? 'contents' : 'none');
        });

        $('.clear-company-input').on('click', function () {
            $(this).css('display', 'none');
            $.searchXhrPool.abortAll();
            clearResults();
            closeResults();
            $input.trigger('focus');
        })
    });

    $.searchXhrPool = [];
    $.searchXhrPool.abortAll = function () {
        $(this).each(function (i, jqXHR) { //  cycle through list of recorded connection
            try {
              jqXHR.abort();  //  aborts connection // it fails sometimes in IOS 14 -+1
            } catch (e) {
              window.captureError('Aborting JqXHR failed.')
            }
            $.searchXhrPool.splice(i, 1); //  removes from list by index
        });
    }

    let isSearching = false;

    function fillResults(term, country) {
        $.searchXhrPool.abortAll();

        let data = {
            search_term: term, search_country_code: country, action: 'search_company',
        };

        return $.ajax({
            type: 'GET', url: window.theme.ajaxUrl, data: data, beforeSend: function (jqXHR) {
                $.searchXhrPool.push(jqXHR);
                $loading.addClass('is-loading');
                isSearching = true;
                $button.css('background-color', isSearchingButtonColor)
                clearResults();
                closeResults();
            }, //  and connection to list
            complete: function (jqXHR) {
                let i = $.searchXhrPool.indexOf(jqXHR);   //  get index for current connection completed
                if (i > -1) {
                    $.searchXhrPool.splice(i, 1);
                } //  removes from list by index
                $loading.removeClass('is-loading');
                isSearching = false;
                $button.css('background-color', '')
                openResults();
            }, success: function (result) {
                if (result.success) {
                    createResultsFromAjax(result.data);
                    //$resultsContainer.html(result.data);
                    //$resultsContainer.scrollTop(0);
                    return true;
                } else {
                    console.log('unexpected result while getting companies:');
                    console.log(result);
                }
            }, error: function (xhr, status, error) {
                console.log('unexpected error while getting companies:');
                console.log(error);
            },
        });
    }

    function createResultsFromAjax(resultData) {
        clearResults();
        let hiddenTemplate = $('.searchResults_item.boiler').clone();//todo add to variable
        $.each(resultData, function (index, item) {
            var listItem = $(hiddenTemplate).clone();
            var companyName = item.company_data.name;
            var regCode = item.company_data.reg_code;
            var countryCode = item.company_data.country_code;
            var companyUrl = item.company_url;
            listItem.find('.company-name').text(companyName);
            listItem.find('.company-reg-code').text('Reg. No. ' + regCode);
            var imgElement = listItem.find('.country-image img');
            var src = imgElement.attr('src');
            var newSrc = src.replace(/\/[^/]+$/, '/' + countryCode.toLowerCase() + '.svg');

            imgElement.attr('src', newSrc);
            listItem.attr('href', companyUrl);
            listItem.removeClass('boiler');
            listItem.show();
            $resultsContainer.append(listItem);
        });
    }

    function isResultsOpen() {
        if ($input.hasClass('show') || $resultsContainer.attr('aria-expanded') === true) {
            return true;
        }
        return false;
    }

    function openResults() {
        if (isResultsOpen() || (getResultsOrFalse() === false) || isSearching) {
            return;
        }
        $inputDropdown.show();
        $resultsDropdown.show();

        return true;
    }

    function closeResults() {
        if (isResultsOpen()) {
            $inputDropdown.hide();
            $resultsDropdown.hide();
        }
    }

    function getResultsOrFalse() {
        let $elements = $('.searchResults_item').not('.boiler');

        if ($elements.length) {
            return $elements;
        }
        return false;
    }

    function clearResults() {
        let $elements = getResultsOrFalse();

        if ($elements) {
            $elements.remove();
        }
    }

    const debounceNavSearch = debounce(
        debounceRate,
        () => {
            let countryCode = $country.val();

            let request = fillResults(searchTerm, countryCode);
            request.then(function () {
                openResults();
            });
        },
        false
    );

    $inputAjaxTrigger.on('keyup', function (e) {

        if (e.keyCode === 27 && $resultsContainer) {//todo KeyboardNav - fix this postMVP
            $resultsContainer.empty();
        }

        // enter key
        if (e.keyCode === 13) {
            return;
        }
        // arrow keys
        if (e.keyCode === 37 || e.keyCode === 39) {
            return;
        }
        if (navigateSearchItems(e, 'js-searchResult')) {
            return;
        }

        // if key is not space
        if (e.which === 32) {
            return;
        }

        let $this = $(this);
        searchTerm = $this.val().toLowerCase();
        // if typed more than 2 characters
        if (searchTerm && searchTerm.length >= 3) {
            debounceNavSearch();

            // open results when clicked on input
            $this.on('click', function () {
                openResults();
            });

            // open results when clicked on button
            $button.on('click', function () {
                //openResults();
            });
        } else {
            closeResults();

            // don't open results when clicked on input
            $this.on('click', function () {
                closeResults();
            });

            // don't open results when clicked on button
            $button.on('click', function () {
                //closeResults();
            });
            return false;
        }
    });

    $(document).on('click keyup', function (e) {
        const $activeElement = $(document.activeElement);
        if ($activeElement.hasClass('js-searchResult') && isResultsOpen()) {
            navigateSearchItems(e, 'js-searchResult')
        }
    });

    // todo Check key navigation
    // HackFIX for not making initial nav happen twice.
    var skipNextContinuingNavigation = false;

    function navigateSearchItems(e, searchItemClass) {
        const isContinuingNavigation = $(document.activeElement).hasClass(searchItemClass);
        const key = e.keyCode;
        if (isContinuingNavigation) {
            if (skipNextContinuingNavigation) {
                skipNextContinuingNavigation = false;
                return false;
            }
            const $searchDropItem = $('.' + searchItemClass + ':focus');
            let $nextNavItem;
            if (key === 38) {
                $nextNavItem = $searchDropItem.prev();
            }
            if (key === 40) {
                $nextNavItem = $searchDropItem.next();
            }

            if (typeof $nextNavItem !== 'undefined' && $nextNavItem.hasClass(searchItemClass)) {
                $nextNavItem.focus()
            } else {
                return key === 38 || key === 40;
            }
        } else {
            const $searchDropItems = $('.' + searchItemClass);
            let $nextNavItem;
            if ($searchDropItems.length && key === 38) {
                $nextNavItem = $searchDropItems.last();
            } else if ($searchDropItems.length && key === 40) {
                $nextNavItem = $searchDropItems.first();
            }

            if (typeof $nextNavItem !== 'undefined') {
                $nextNavItem.focus()
            } else {
                return key === 38 || key === 40;
            }
            skipNextContinuingNavigation = true;
        }
        return true;
    }

    $country.on('change', function () {
        let $this = $(this);
        Cookies.set('search_country', $this.val());
    })
    $countryAjaxTrigger.on('change', function () {
        let $this = $(this);
        let searchTerm = $input.val().toLowerCase();
        let countryCode = $this.val();
        if (searchTerm && searchTerm.length >= 3) {
            fillResults(searchTerm, countryCode);
        }
    })

    // close results when clicked outside form
    $(window).on('click', function (e) {
        const $target = $(e.target);
        if (!$target.closest($form).length && isResultsOpen()) {
            closeResults();
        }
    });
}
