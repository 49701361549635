export function searchCompany(searchTerm, countryCode) {
    // ToDo Mb more params like element etc.? or mb we should return Promise.
    var data = {
        action: 'ajax_search_company',
        search_term: searchTerm,
        country_code: countryCode,
    };
    return $.ajax({
        type: 'get', url: window.theme.ajaxUrl, data: data,
        beforeSend: function () {
            // mb not needed. depends if we return Promise or not.
        }, complete: function () {
            // mb not needed. depends if we return Promise or not.
        }, success: function (response) {
            // mb not needed. depends if we return Promise or not.
            if (response.error) {
                // Check if the error message contains the specified text
                console.log(response.error);
                // ToDo Show floating error (like modal) with the contents of response.error
            } else {
                console.log(response);
            }
        },
    });
}

export function handleFloatingButtonRedirect() {
    $('.floating-search-button').on('click touchstart', function(event) {
        event.preventDefault();
        window.location = $(this).data('search-page');
    })
}
