import {throttle} from 'throttle-debounce';

export function initializeDropdown() {
    $(document).ready(function() {
//console.log('displayControl :: initialized');
/* toDo THIS IS TEST CODE AREA */
/**/
    });
}

export const handleFloatingSearchVisibilityThrottled = () => {
/*console.log('handleFloatingSearchButtonThrottled');*/
    return throttle(250, () => handleFloatingSearchVisibility() );
}

/* Floating Search Button visibility logic:
*   - show when (non-floating) SearchForm's search button is scrolled out of view
*   - show when (mobile) navBar is visible
*   - hide when mini-cart is opened */
export function handleFloatingSearchVisibility() {
    let $form = $('.js-searchForm');
    let navHeight = parseFloat($('.main-header-inner').css('height'));
    let elementBottom = 0;
    if ($form.length) {
        elementBottom = $form.offset().top + $form.outerHeight() - navHeight;
    }
    let $searchContainer = $('.fixed-search-container')
    let navBarVisible = $('#navbar-menu').hasClass('show');
    let miniCartOpen = $('#mini-cart-dropdown').hasClass('show');
    if (($(window).scrollTop() >= elementBottom || navBarVisible ) && !miniCartOpen) {
        $searchContainer.fadeIn();
    } else {
        $searchContainer.fadeOut();
    }
}
